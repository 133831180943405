import React from "react";
import {
  Grid,
  Container,
  Box,
  Header,
  SpaceBetween,
  Link,
} from "@amzn/awsui-components-react";
import "./Home.css";
// import { withAuthenticator } from "aws-amplify-react";
import UploadFile from "./UploadFile";

const Home = ({ badgePhoto, userEmail }) => {
  return (
    <Box>
      <Grid
        gridDefinition={[
          {
            colspan: { xl: "4", l: "5", s: "6", xxs: "10" },
            offset: { xl: "3", l: "2", s: "1", xxs: "1" },
          },
          {
            colspan: { xl: "2", l: "3", s: "4", xxs: "10" },
            offset: { s: "0", xxs: "1" },
          },
        ]}
        className="custom-home__header first-section"
      >
        <Box fontWeight="light">
          <span className="custom-home__category">
            THIS TOOL WILL BE DEPRECATED ON JULY-2023.
          </span>
        </Box>

        <Box className="header-badge">
          <img src={badgePhoto} width="60em" alt="header-badge"></img>
        </Box>
      </Grid>
      <Grid
        gridDefinition={[{ colspan: { xxs: "12" } }]}
        className="custom-home__header second-section"
      >
        <Box>
          <Grid
            gridDefinition={[
              {
                colspan: { xl: "4", l: "5", s: "6", xxs: "10" },
                offset: { xl: "3", l: "2", s: "1", xxs: "1" },
              },
              {
                colspan: { xl: "2", l: "3", s: "4", xxs: "10" },
                offset: { s: "0", xxs: "1" },
              },
            ]}
          >
            {/* Main Title */}
            <div className="custom-home__header-title">
              <Box
                variant="h1"
                fontWeight="bold"
                color="inherit"
                padding={{ bottom: "s" }}
              >
                SAP Calculator
              </Box>
              <Box fontWeight="light" variant="h1" color="#FF0000">
                <span className="custom-home__header-sub-title animate__animated animate__fadeIn animate__delay-1s">
                  THIS TOOL WILL BE DEPRECATED ON JULY-2023.
                </span>
              </Box>
              <Box
                fontWeight="normal"
                fontSize="body-m"
                color="inherit"
                padding={{ bottom: "s" }}
              >
                <span className="custom-home__header-sub-title animate__animated animate__fadeIn animate__delay-1s">
                  Configure a cost estimate that fits your unique business
                  needs, with different pricing, and availability options.
                </span>
              </Box>
            </div>

            {/* Wiki Link */}
            <Container
              header={
                <Header variant="h4" headingTagOverride="h2">
                  Learn More
                </Header>
              }
              className="animate__animated animate__fadeIn animate__delay-1s"
            >
              <SpaceBetween size="sm">
                <Box color="inherit">
                  <p>For more details visit the SAP Calculator Wiki page </p>
                  <br />
                  <Link
                    className="wiki-link-button"
                    fontSize="body-m"
                    href="https://w.amazon.com/bin/view/AWS_Cost_Estimator_for_SAP_-_FAQ"
                  >
                    Wiki Page
                  </Link>
                </Box>
              </SpaceBetween>
            </Container>
          </Grid>
        </Box>
      </Grid>

      <Box margin={{ top: "x" }} padding={{ top: "xxl" }}>
        <Grid
          className="custom-home-main-content-area"
          gridDefinition={[
            {
              colspan: { xl: "4", l: "5", s: "6", xxs: "10" },
              offset: { xl: "3", l: "2", xxs: "1" },
            },
            {
              colspan: { xl: "2", l: "3", s: "4", xxs: "10" },
              offset: { s: "0", xxs: "1" },
            },
          ]}
        >
          <div>
            <SpaceBetween size="l">
              <div>
                <Header variant="h3" headingTagOverride="h2" padding="n">
                  How does it work?
                </Header>
                <Container>
                  <Box>
                    <img src="./HIW.png" alt="How It Works" width="100%" />
                  </Box>
                  {/* <Box fontSize="body-s" padding={{ top: "xs" }}>
                 
                    Step 1: Just download one of the templates from the below
                    links and populate the details.
                  </Box>
                  <Box fontSize="body-s" padding={{ top: "xs" }}>
                    Step 2: Upload the file.
                  </Box>
                  <Box fontSize="body-s" padding={{ top: "xs" }}>
                    Step 3: SAP Calculator does the magic in the back.
                  </Box>
                  <Box fontSize="body-s" padding={{ top: "xs" }}>
                    Step 4: Get the results in your mail box.
                  </Box>

                  <Box
                    fontSize="body-s"
                    fontWeight="light"
                    color="inherit"
                    padding={{ top: "xs" }}
                  >
                    <span>
                      For more details visit the&nbsp;
                      <a href="https://w.amazon.com/bin/view/AWS_Cost_Estimator_for_SAP_-_FAQ">
                        wiki page
                      </a>
                    </span>
                  </Box> */}
                </Container>
              </div>
            </SpaceBetween>
          </div>
          <div className="custom-home__sidebar">
            {/* Template Box */}
            <Container
              header={
                <Header variant="h4" headingTagOverride="h2">
                  Templates
                </Header>
              }
              className="animate__animated animate__fadeIn animate__delay-1s"
            >
              <SpaceBetween size="sm">
                <Box fontWeight="normal">
                  Download input template spreadsheet
                </Box>
                <Box fontWeight="light" padding={{ top: "xs" }}>
                  <a href="https://sapcalculator.amazon.dev/spreadsheet/input_sap.xlsx">
                    For SAP Workloads
                  </a>
                </Box>
                <Box fontWeight="light" padding={{ top: "xs" }}>
                  <a href="https://sapcalculator.amazon.dev/spreadsheet/input_generic.xlsx">
                    For Other Workloads
                  </a>
                </Box>
                <p className="animate__animated animate__fadeIn animate__delay-1s"></p>
              </SpaceBetween>
            </Container>
            <SpaceBetween direction="vertical" size="xxl">
              <Container
                className="upload-file-section"
                header={
                  <Header variant="h4" headingTagOverride="h2" padding="n">
                    Upload File
                  </Header>
                }
              >
                <UploadFile userEmail={userEmail} />
              </Container>
            </SpaceBetween>
          </div>
        </Grid>
      </Box>
    </Box>
  );
};

export default Home;
