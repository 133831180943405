import React, { useState, useEffect } from "react";
import {
  AppLayout,
  Container,
  Box,
  Grid,
  Header,
  Button,
} from "@amzn/awsui-components-react";
import "./App.css";
// import { withAuthenticator } from "aws-amplify-react";
import Amplify from "aws-amplify";
import config from "./aws-exports";
import { Auth, Hub } from "aws-amplify";
import Home from "./components/Home";

Amplify.configure(config);

const App = () => {
  const [user, setUser] = useState(null);
  const [badgePhoto, setBadgePhoto] = useState(null);
  const [userEmail, setUserEmail] = useState(null);

  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      // eslint-disable-next-line default-case
      switch (event) {
        case "signIn":
        case "cognitoHostedUI":
          getUser().then((userData) => setUser(userData));
          break;
        case "signOut":
          setUser(null);
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          console.log("Sign in failure", data);
          break;
      }
    });

    getUser().then((userData) => {
      setUser(userData);
      if (userData) {
        setBadgePhoto(
          "https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=" +
            userData.signInUserSession.idToken.payload.identities[0].userId
        );
        setUserEmail(userData.signInUserSession.idToken.payload.email);
      }
    });
  }, []);

  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then((userData) => userData)
      .catch(() => console.log("Not signed in"));
  }

  return (
    <div className="awsui">
      {user ? (
        <Home user={user} badgePhoto={badgePhoto} userEmail={userEmail} />
      ) : (
        <Grid gridDefinition={[{ colspan: 6, offset: 3 }]}>
          <Box className="login-form" textAlign="center" color="inherit">
            <h1 color="#ff9900;">SAP Calculator</h1>
            <Button
              variant="primary"
              onClick={() =>
                Auth.federatedSignIn({ provider: "LoginWithAmazon" })
              }
            >
              Login With Amazon Midway
            </Button>
          </Box>
        </Grid>
      )}
      ;
    </div>
  );
};

export default App;

// export default withAuthenticator(App);
