/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "us-west-2",
  aws_cognito_identity_pool_id:
    "us-west-2:08286b77-bd0f-4999-ad2a-98819522212a",
  aws_cognito_region: "us-west-2",
  aws_user_pools_id: "us-west-2_ZbMroLILh",
  aws_user_pools_web_client_id: "4b6at4eecvps236giedsaomb7o",
  oauth: {
    domain: "sapcalculator.auth.us-west-2.amazoncognito.com",
    scope: ["profile", "openid"],
    redirectSignIn: "https://sapcalculator.amazon.dev",
    //redirectSignIn: "http://localhost:3000",
    redirectSignOut: "https://sapcalculator.amazon.dev",
    //redirectSignOut: "http://localhost:3000",

    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
  aws_user_files_s3_bucket: "sapcalculator-data-bucket",
  aws_user_files_s3_bucket_region: "us-west-2",
};

export default awsmobile;
