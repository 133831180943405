import React, { useState, useRef } from "react";
import "./UploadFile.css";
import moment from "moment";
import {
  Grid,
  Alert,
  ProgressBar,
  FormField,
  Input,
  Button,
  Spinner,
} from "@amzn/awsui-components-react/polaris";
import { Storage } from "aws-amplify";
import validator from "validator";

function UploadFile({ userEmail }) {
  const ref = useRef();

  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({
    label: "Uploading File",
    status: "in-progress",
    progress: 0,
  });
  const [fileContent, setFileContent] = useState("");
  const [visible, setVisible] = useState(false);
  const [alertType, setAlertType] = useState("error");
  const [alertMessage, setAlertMessage] = useState("Pleaase select a file.");
  const [fileName, setFileName] = useState("");
  const [fileType, setFileType] = useState("");
  const [url, setUrl] = useState("");
  const [urlError, setUrlError] = useState("");

  const checkFileSize = (file) => {
    return file && file.size < 8388608 ? true : false;
  };

  const validateUrl = (url) => {
    setUrl(url);
    if (validator.isURL(url)) {
      setUrlError("");
      return true;
    } else {
      if (url.length === 0) {
        setUrlError("Url cannot be empty.");
      }
      setUrlError("Url is invalid!!");
      return false;
    }
  };

  const onChange = (e) => {
    setFileContent(e.target.files[0]);
    setFileName(e.target.files[0].name);
    setFileType(e.target.files[0].type);
    console.log(e);
  };

  const uploadFile = () => {
    if (!validateUrl(url)) {
      setAlertType("error");
      setAlertMessage("SFDC Url field is required.");
      setVisible(true);
      return;
    }
    if (ref.current.files.length === 0) {
      setAlertType("error");
      setAlertMessage("Please select a file.");
      setVisible(true);
      return;
    }

    // Check File Size
    if (!checkFileSize(fileContent)) {
      setAlertType("error");
      setAlertMessage("File size should be less than 8MB");
      setVisible(true);
      return;
    }

    let ext = fileContent.name.split(".").pop().toLowerCase();
    let fileFormats = ["xls", "xlsx"];
    if (!fileFormats.includes(ext)) {
      console.log("Invalid file format");
      return false;
    }

    let fileName =
      "sap2/" +
      fileContent.name.substr(0, fileContent.name.indexOf(ext) - 1) +
      "_" +
      moment.utc().format("hhmmss_MMDDYYYY") +
      "." +
      ext;

    setIsUploading(true);
    setAlertType("info");
    setAlertMessage("Uploading File...");
    setVisible(true);
    Storage.put(fileName, fileContent, {
      contentType: fileType,
      metadata: { src: "midway", sap: userEmail, sfdcUrl: url },
      progressCallback(progress) {
        setUploadProgress({
          status: "in-progress",
          label: "Uploading File",
          progress: (progress.loaded / progress.total) * 100,
        });
        console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
      },
    })
      .then((result) => {
        console.log(result);
        setIsUploading(false);
        setAlertType("success");
        setAlertMessage(
          "File Uploaded successfully. You will get a response in email on your email id : " +
            userEmail
        );
        setVisible(true);
        ref.current.value = "";
        setFileContent(null);
        setFileName(null);
        setFileType(null);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="animate__animated animate__fadeIn animate__delay-1s">
      <div className="form animate__animated animate__fadeIn animate__delay-1s">
        <Grid
          gridDefinition={[
            {
              colspan: { xl: "6", l: "6", s: "12", xxs: "12" },
            },
            {
              colspan: { xl: "6", l: "6", s: "12", xxs: "12" },
            },
          ]}
        >
          <div className="button-wrap">
            <FormField
              label="SFDC Opportunity"
              ariaLabel="URL"
              errorText={urlError}
            >
              <Input
                name="url"
                type="text"
                value={url}
                placeholder="URL"
                onChange={({ detail }) => validateUrl(detail.value)}
              />
            </FormField>
            <br />
            {/* <label className="new-upload-button" for="file">
              Browse File
            </label> */}
            <input
              type="file"
              required
              id="file"
              ref={ref}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={(evt) => onChange(evt)}
            />
          </div>
          <div>
            <Button
              className="uploadButton"
              variant="primary"
              disabled={isUploading}
              onClick={uploadFile}
            >
              {isUploading ? <Spinner size="normal" /> : "Upload"}
            </Button>
          </div>
        </Grid>
        <Grid
          gridDefinition={[
            {
              colspan: 12,
            },
          ]}
        >
          <div className="file-upload-alert">
            {isUploading ? (
              <ProgressBar
                status={uploadProgress.status}
                value={uploadProgress.progress}
                label={uploadProgress.label}
              />
            ) : (
              <Alert
                className="uploading-alert"
                onDismiss={() => setVisible(false)}
                visible={visible}
                dismissAriaLabel="Close alert"
                dismissible
                type={alertType}
              >
                {alertMessage}
              </Alert>
            )}
          </div>
        </Grid>
      </div>
      {/* <Flashbar items={items} /> */}
    </div>
  );
}

export default UploadFile;
